import React from 'react'

import Layout from '../components/Layout'
// import SEO from '../components/Helpers/seo'
// import Welcome from '../components/Welcome'

const NotFoundPage = () => (
  <Layout>
  </Layout>
)

export default NotFoundPage
